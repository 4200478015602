import React from "react";
import '../css/Code.css'

class Code extends React.Component{
    HandleClick = (e) => {
        var code = document.getElementById("OrderCode").innerHTML;
        window.location.href = `mailto:info@tromm.nl?subject=${code}`;
    }

    refreshPage = () => {
        window.location.reload(false);
      }

    render(){
        return(
            <article className="CodeBox" id='Code'>
                <section className="Code">
                    <h1>Order code</h1>
                    <p id="OrderCode"></p>
                    <button className="CodeButton" onClick={this.HandleClick}>Email Order</button>
                    <button className="CodeButtonRe" onClick={this.refreshPage}>Restart</button>
                </section>
            </article>
            
        )
    }

}

export default Code