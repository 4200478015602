import React from "react";
import '../css/ColorBox.css';

let ColorPlace

class ColorBox extends React.Component{
    constructor(props){
        super(props)
        this.HandleClick = this.HandleClick.bind(this);
    }

    HandleClick = (e) =>{

        ColorPlace = e.target.id 
        localStorage.setItem(this.props.BikePart, ColorPlace)
        document.getElementById(this.props.BikePart+ "Colour").innerHTML = `<h1 style=padding-top:15px;padding-bottom:15px; class='OptionH1'>${this.props.BikePart+ "Colour"}</h1><p>${localStorage.getItem(this.props.BikePart)}</p>`

        if(this.props.BikePart === "Mainframe" && ColorPlace.includes("Lacquer")){
            localStorage.setItem("Rearframe", "Black")
            document.getElementById("RearframeColour").style.backgroundColor = "#9BC53D"
            document.getElementById("RearframeColour").style.color = "#FFF"
        }

        if(this.props.BikePart === "Rearframe" &&  ColorPlace !== "Black" && localStorage.getItem("Mainframe").includes("Lacquer") ){
            alert("Laquer colours can only have a Black rearframe")
            localStorage.setItem("Rearframe", "Black")
        }
    }

    render(){
        return(
            <article className='ColorBox'onClick={this.HandleClick}>
                <p className='Color' style={this.props.Color}></p>
                <p className='Text'>{this.props.ColorText}</p>
                <article className="Overlay" id={this.props.ColorText}>{ColorPlace}</article>
                
            </article> 
        )
    }
}

export default ColorBox