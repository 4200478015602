import React from 'react'
import Plus from '../img/plus.svg'
import '../css/Option.css'
import Chose from './Chose'
import RearframeColor from './RearframeColour'
import MainframeColor from './MainframeColour'

class Option extends React.Component{
    constructor(props){
        super(props)
        this.HandleClick = this.HandleClick.bind(this);
        this.state = {IsClicked: false};
    }

    HandleClick = () =>{
        if(!this.state.IsClicked){
            this.setState({IsClicked: true})
        }
        else{
            this.setState({IsClicked: false})
        }
    }

    render(){
        const IsClicked = this.state.IsClicked;
        let ShowList;
        if(IsClicked){
            switch(this.props.ItemName){
                case "MainframeColour":
                    ShowList = <MainframeColor />
                    break
                case "RearframeColour":
                    ShowList = <RearframeColor />
                    break
                case "Mudgard or Rack":
                    ShowList = <section className='SectionWidth'>
                                <Chose  Img={this.props.Mudgard} ImgDesc={this.props.ImgDescMudgard} Title={this.props.TitleMudgard} Description={this.props.DescMudgard}></Chose>
                                <Chose  Img={this.props.RearRack} ImgDesc={this.props.ImgDescRearRack} Title={this.props.TitleRearRack} Description={this.props.DescRearRack}></Chose>
                            </section>
                    break
                case "Gears":
                    ShowList = <section className='SectionWidth'>
                                <Chose  Img={this.props.Tweeversn} ImgDesc={this.props.ImgDescTweeversn} Title={this.props.TitleTweeversn} Description={this.props.DescTweeversn}></Chose>
                                <Chose  Img={this.props.Zesversn} ImgDesc={this.props.ImgDescZesversn} Title={this.props.TitleZesversn} Description={this.props.DescZesversn}></Chose>
                            </section>
                    break
                case "Handlebars":
                    ShowList = <section className='SectionWidth'>
                                <Chose  Img={this.props.Medium} ImgDesc={this.props.ImgDescMedium} Title={this.props.TitleMedium} Description={this.props.DescMedium}></Chose>
                                <Chose  Img={this.props.High} ImgDesc={this.props.ImgDescHigh} Title={this.props.TitleHigh} Description={this.props.DescHigh}></Chose>
                                <Chose  Img={this.props.Sport} ImgDesc={this.props.ImgDescSport} Title={this.props.TitleSport} Description={this.props.DescSport}></Chose>
                            </section>
                    break
                case "Lights":
                    ShowList = <section className='SectionWidth'>
                                <Chose  Img={this.props.Ref} ImgDesc={this.props.ImgDescRef} Title={this.props.TitleRef} Description={this.props.DescRef}></Chose>
                                <Chose  Img={this.props.Bat} ImgDesc={this.props.ImgDescBat} Title={this.props.TitleBat} Description={this.props.DescBat}></Chose>
                                <Chose  Img={this.props.Dyn} ImgDesc={this.props.ImgDescDyn} Title={this.props.TitleDyn} Description={this.props.DescDyn}></Chose>
                            </section>
                    break
                case "Seatpost":
                    ShowList = <section className='SectionWidth'>
                                <Chose  Img={this.props.Standard} ImgDesc={this.props.ImgDescStandard} Title={this.props.TitleStandard} Description={this.props.DescStandard}></Chose>
                                <Chose  Img={this.props.Extend} ImgDesc={this.props.ImgDescExtend} Title={this.props.TitleExtend} Description={this.props.DescExtend}></Chose>
                                <Chose  Img={this.props.Tel} ImgDesc={this.props.ImgDescTel} Title={this.props.TitleTel} Description={this.props.DescTel}></Chose>
                            </section>
                    break
                default:
                    console.log("error") 
            }
        }
        return(
            
            <article className='Field' >
                <button className='Option' id={this.props.ItemName} onClick={this.HandleClick}>
                    <h1 className='OptionH1'>{this.props.ItemName}</h1>
                    <figure className='OptionFigure'>
                        <img className='OptionFigureImg' src={Plus} alt="down arrow"></img>
                    </figure>
                </button>
                {ShowList}
            </article> 
        )
    }
}

export default Option