import React from "react";
import '../css/Submit.css'

class Submit extends React.Component{
    HandleClick = (e) => {
        let Item;
        Item = e.target.id
        console.log(Item)
        this.CheckArray()
    }

    CheckArray = () =>{
        var keys = Object.keys(localStorage),
        i = keys.length,
        map = new Map();

        while ( i-- ) {
            map.set(localStorage.key(i), localStorage.getItem(keys[i]))
        }
        if(map.size < 7){
            alert("Please fill al options")
        }else{
            this.ConvertToCode(map)
        }
        
    }

    ConvertToCode = (values) =>{
        let Code;
        const CodeMap = new Map()
        const ColorMap = new Map([['Black', 'BK'],
                                ['Ocean Blue', 'OB'],
                                ['Dune Sand', 'DS'],
                                ['Flame Lacquer', 'FLaq'],
                                ['Black Lacquer', 'BKLaqg'],
                                ['Yazu Lime', 'YL'],
                                ['Matcha Green', 'MT'],
                                ['Racing Green', 'RG']])
        const HandlebarMap = new Map([['High', 'H'],['Medium','M'],['Low', 'S']])
        const GearsMap = new Map([['Urban', '2'],['Explore','6']])
        const MudgardRackMap = new Map([['Mudgard', 'L'],['Rear Rack','R']])
        const LightsMap = new Map([['Reflector', 'REV'],['Dynamo','HDSV8'],['Battery', 'BAT3']])
        const SeatpostMap = new Map([['Standard', ''],['Extended','SP6'],['Telescopic', 'SPT']])
        for (const [key, value] of values.entries()) {
            switch(key){
                case 'Handlebars':
                    CodeMap.set('Handlebars', HandlebarMap.get(value))
                    break;
                case 'Gears':
                    CodeMap.set('Gears', GearsMap.get(value))
                    break;
                case 'Mainframe':
                    CodeMap.set('Mainframe','m' + ColorMap.get(value) + '/')
                    break;
                case 'Rearframe':
                    CodeMap.set('Rearframe','r' + ColorMap.get(value) + '/FCB/')
                    break;
                case 'Mudgard or Rack':
                    CodeMap.set('Mudgard or Rack', MudgardRackMap.get(value) + '/')
                    break;
                case 'Lights':
                    CodeMap.set('Lights', LightsMap.get(value) + '/')
                    break;
                case 'Seatpost':
                    CodeMap.set('Seatpost', SeatpostMap.get(value) + '/')
                    break;
                default:
                    break;
            }
          }
          Code = CodeMap.get('Handlebars') + CodeMap.get('Gears') + CodeMap.get('Mudgard or Rack') + CodeMap.get('Mainframe') + CodeMap.get('Rearframe') + CodeMap.get('Lights') + CodeMap.get('Seatpost')
          var CodeCode = document.getElementById("OrderCode")
          CodeCode.innerHTML = Code
          var Codebox = document.getElementById("Code")
          Codebox.style.display = "flex";
    }

    render(){
        return(
            <article className="SubmitBox">
                <section className='Submit' onClick={this.HandleClick} id={this.props.Title}>
                    <h1 className='SubmitH1' id={this.props.idh1}>{this.props.Title}</h1>
                    <article className='SubmitOverlay' id={this.props.Title}></article>
                </section>
            </article>
            
        )
    }
}

export default Submit