import React from "react";
import '../css/Header.css'
import logo from '../img/logo.svg'
import tromm from '../img/tromm.png'

class Header extends React.Component{
    render(){
        return(
            <section className="Header">
                <figure className='HeaderFigure'>
                    <img className='HeaderImg' src={logo} alt="Brompton Bike Builder logo"></img>
                </figure>
                <figure className='HeaderFigure'>
                    <a href="https://www.tromm.nl/">
                        <img className='HeaderImg' src={tromm} alt="Brompton Bike Builder logo"></img>
                    </a>
                </figure>
            </section>
        )
    }

}

export default Header