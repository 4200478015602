import React from 'react'
import '../css/Chose.css'

class Chose extends React.Component{
    constructor(props){
        super(props)
        this.HandleClick = this.HandleClick.bind(this);
    }

    HandleClick = (e) => {
        let Item;
        Item = e.target.id
        console.log(Item)
        this.CreateList(Item)
    }

    MarkChecked = (id) =>{
        document.getElementById(id).innerHTML = `<h1 style=padding-top:15px;padding-bottom:15px; class='OptionH1'>${id}</h1><p>${localStorage.getItem(id)}</p>`
    }

    CreateList = (Item) => {
        switch(Item){
            case 'Medium':
            case 'High':
            case 'Low':
                localStorage.setItem("Handlebars", Item)
                this.MarkChecked("Handlebars")
                break;
            case 'Urban':
            case 'Explore':
                localStorage.setItem("Gears", Item)
                this.MarkChecked("Gears")
                break;
            case 'Mudgard':
            case 'Rear Rack':
                localStorage.setItem("Mudgard or Rack", Item)
                this.MarkChecked("Mudgard or Rack")
                break;
            case 'Reflector':
            case 'Battery':
            case 'Dynamo':
                localStorage.setItem("Lights", Item)
                this.MarkChecked("Lights")
                break;
            case 'Standard':
            case 'Extended':
            case 'Telescopic':
                localStorage.setItem("Seatpost", Item)
                this.MarkChecked("Seatpost")
                break;
            case 'Submit':
            case 'idh1':
                console.log("Submit")
                document.getElementById("Submit").style.backgroundColor = "#1B9AAA"
                this.CheckArray()
                break;
            default:
                console.log("wrong entry")
        }
    }


    render(){
        return(
            <section className='Chose' onClick={this.HandleClick} id={this.props.Title}>
                <figure className='ChoseFigure'>
                    <img className='ChoseFigureImg' src={this.props.Img} alt={this.props.ImgDesc}></img>
                </figure>
                <h1 className='ChoseH1' id={this.props.idh1}>{this.props.Title}</h1>
                <h3 className='ChoseH3'>{this.props.Description}</h3>
                <article className='Overlay' id={this.props.Title}></article>
            </section>
        )
    }
}

export default Chose