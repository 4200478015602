import React from 'react';
import Option from './Option';
import Code from './Code'
import Header from './Header';
import Submit from './Submit';

/* all images, add images here and to img directory*/
import Medium from '../img/Medium.svg'
import High from '../img/High.svg'
import Sport from '../img/Sport.svg'
import Tweeversn from '../img/2versn.svg'
import Zesversn from '../img/6versn.svg'
import Mudgard from '../img/Mudgard.svg'
import RearRack from '../img/RearRack.svg'
import Reflector from '../img/Reflector.svg'
import Battery from '../img/Battery.svg'
import Dynamo from '../img/Dynamo.svg'
import Standard from '../img/seatpost.svg'
import Extend from '../img/seatpostExt.svg'
import Telescopic from '../img/seatpostTel.svg'
import Footer from './Footer';

/*main component make changes */
class App extends React.Component{
        render(){
        localStorage.clear()
        return(
            <section className='Field'>
                <Header></Header>
                {/*type of handlebar, 1 type per tile*/}
                <Option ItemName="Handlebars" 
                        //tile1
                        Medium={Medium} 
                        ImgDescMedium='Medium'
                        TitleMedium='Medium'
                        DescMedium='Between comfort and speed'
                        //tile2
                        High={High} 
                        ImgDescHigh='High'
                        TitleHigh='High'
                        DescHigh='Ultimate comfort'
                        //tile3
                        Sport={Sport}
                        ImgDescSport='low'
                        TitleSport='Low'
                        DescSport='All the speed you can get'
                        ></Option>
                <Option ItemName="Gears"
                        //tile1
                        Tweeversn={Tweeversn} 
                        ImgDescTweeversn='2 speed'
                        TitleTweeversn='Urban'
                        DescTweeversn='2 Speed'
                        //tile2
                        Zesversn={Zesversn}
                        ImgDescZesversn='6 speed'
                        TitleZesversn='Explore'
                        DescZesversn='6 Speed'
                        ></Option>
                <Option ItemName="Mudgard or Rack" 
                        //tile1
                        Mudgard={Mudgard} 
                        ImgDescMudgard='mudgard'
                        TitleMudgard='Mudgard'
                        DescMudgard='For good and bad weather'
                        //tile2
                        RearRack={RearRack}
                        ImgDescRearRack='rear rack'
                        TitleRearRack='Rear Rack'
                        DescRearRack='For more stability'
                        ></Option>
                <Option ItemName="MainframeColour" className="Mainframe"></Option>
                <Option ItemName="RearframeColour" className="Rearframe" ></Option>
                <Option ItemName="Lights"
                        //tile1
                        Ref={Reflector} 
                        ImgDescRef='Reflector'
                        TitleRef='Reflector'
                        DescRef='Between comfort and speed'
                        //tile2
                        Bat={Battery} 
                        ImgDescBat='Battery'
                        TitleBat='Battery'
                        DescBat='Ultimate comfort'
                        //tile3
                        Dyn={Dynamo}
                        ImgDescDyn='Dynamo'
                        TitleDyn='Dynamo'
                        DescDyn='All the speed you can get'
                ></Option>
                <Option ItemName="Seatpost"
                        //tile1
                        Standard={Standard} 
                        ImgDescStandard='Standard'
                        TitleStandard='Standard'
                        DescStandard='84cm inside leg'
                        //tile2
                        Extend={Extend} 
                        ImgDescExtend='Battery'
                        TitleExtend='Extended'
                        DescExtend='89cm inside leg'
                        //tile3
                        Tel={Telescopic}
                        ImgDescTel='Telescopic'
                        TitleTel='Telescopic'
                        DescTel ='99cm inside leg'
                ></Option>
                <Submit Title="Submit" id="Submit" idh1="idh1"></Submit>
                <Code></Code>
                <Footer></Footer>
            </section>
        )
    }
}

export default App