import React from 'react'
import '../css/ChoseColor.css'
import ColorBox from './ColorBox'

class RearframeColor extends React.Component{
    render(){
        const Black = {
            backgroundColor: 'black',
        };

        const RacingGreen = {
            backgroundColor: '#2c5336',
        };

        const MatchaGreen = {
            backgroundColor: '#8bac81',
        };

        const DuneSand = {
            backgroundColor: '#9E9764',
        };

        const YazuLime = {
            backgroundColor: '#9ec035',
        };

        const OceanBlue = {
            backgroundColor: '#1D334A',
        };

        return(
            <section className='ChoseCenter'>
                <section className='ChoseColor'>
                    <ColorBox BikePart='Rearframe' Color={Black} ColorText='Black'></ColorBox>
                    <ColorBox BikePart='Rearframe' id="Hide" Color={RacingGreen} ColorText='Racing Green'></ColorBox>
                    <ColorBox BikePart='Rearframe' id="Hide" Color={MatchaGreen} ColorText='Matcha Green'></ColorBox>
                    <ColorBox BikePart='Rearframe' id="Hide" Color={DuneSand} ColorText='Dune Sand'></ColorBox>
                    <ColorBox BikePart='Rearframe' id="Hide" Color={YazuLime} ColorText=' Yazu Lime'></ColorBox>
                    <ColorBox BikePart='Rearframe' id="Hide" Color={OceanBlue} ColorText='Ocean Blue'></ColorBox>
                    <p className='Disclaimer'>*Color may differ from real world</p>
                </section>
            </section>
        )
    }
}

export default RearframeColor