import React from "react";
import '../css/Footer.css'

class Footer extends React.Component{
    render(){
        return(
            <section className="Footer">
                <article className="FooterInside">
                    <div className="Info">
                    <p>Tromm Tweewielers B.V.</p>
                    <hr></hr>
                    <ul>
                        <li>Europaplein 45 1078 GV Amsterdam</li>
                        <li>020 - 664 20 99</li>
                        <li>info@tromm.nl</li>
                    </ul>
                    </div>
                    <div className="Info">
                    <p>OPENINGTIMES:</p>
                    <hr></hr>
                    <ul>
                        <li>Monday: Gesloten</li>
                        <li>Tuesday: 09:00 - 18:00</li>
                        <li>Wednsdag: 09:00 - 18:00</li>
                        <li>Thursday: 09:00 - 18:00*</li>
                        <li>Friday: 09:00 - 18:00</li>
                        <li>Saturday: 09:00 - 17:00</li>
                        <li>Sunday: Gesloten</li>
                    </ul>
                        </div>
                </article>
                <h3 className="Credits">Made by Sam Verlaan</h3>
            </section>
        )
    }
}

export default Footer