import React from 'react'
import '../css/ChoseColor.css'
import ColorBox from './ColorBox'

class MainframeColor extends React.Component{
    render(){

        const Black = {
            backgroundColor: 'black',
        };

        const RacingGreen = {
            backgroundColor: '#2c5336',
        };

        const MatchaGreen = {
            backgroundColor: '#8bac81',
        };

        const DuneSand = {
            backgroundColor: '#9E9764',
        };

        const YazuLime = {
            backgroundColor: '#9ec035',
        };

        const OceanBlue = {
            backgroundColor: '#1D334A',
        };

        const BlackLacquer = {
            backgroundColor: '#282828',
        };

        const FlameLacquer = {
            backgroundColor: '#c52002',
        };

        return(
            <section className='ChoseCenter'>
                <section className='ChoseColor'>
                    <ColorBox BikePart='Mainframe' Color={Black} ColorText='Black'></ColorBox>
                    <ColorBox BikePart='Mainframe' Color={RacingGreen} ColorText='Racing Green'></ColorBox>
                    <ColorBox BikePart='Mainframe' Color={MatchaGreen} ColorText='Matcha Green'></ColorBox>
                    <ColorBox BikePart='Mainframe' Color={DuneSand} ColorText='Dune Sand'></ColorBox>
                    <ColorBox BikePart='Mainframe' Color={YazuLime} ColorText='Yazu Lime'></ColorBox>
                    <ColorBox BikePart='Mainframe' Color={OceanBlue} ColorText='Ocean Blue'></ColorBox>
                    <ColorBox BikePart='Mainframe' Color={BlackLacquer} ColorText='Black Lacquer'></ColorBox>
                    <ColorBox BikePart='Mainframe' Color={FlameLacquer} ColorText='Flame Lacquer'></ColorBox>
                    <p className='Disclaimer'>*Color may differ from real world</p>
                </section>
            </section>
        )
    }
}

export default MainframeColor